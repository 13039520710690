<template>
	<div class="input-container">
		<h2>{{ languageStrings.userNameLabel }}</h2>
		<input class="curved-border extra-padding-text-fields" type="text" :placeholder="languageStrings.userNameLabel" v-model="displayName" />
		<div class="checkbox center-items">
			<input
				type="checkbox"
				id="ageCheck"
				name="Age Check"
				:title="languageStrings.ageCheckText"
				v-model="ageCheck"
			/>
			<label for="ageCheck" :title="languageStrings.ageCheckText">{{ languageStrings.ageCheckText }}</label>
		</div>
	</div>
	<div class="encore-style-button-container">
		<button
			class="btn encore-style-button curved-border"
			:title="languageStrings.continueText"
			@click="submitToParent()"
		>
			{{ languageStrings.continueText }}
		</button>
	</div>
</template>

<script>
export default {
	name: "UserNameForm",
	props: {
		languageErrorStrings: Object,
		languageStrings: Object,
	},
	components: {},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			displayName: "",
			ageCheck: null,
			newAccountProperty: {
				propertyName: "",
				propertyValue: "",
				componentName: "UserNameForm",
			},
		};
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		submitToParent() {
			if (!this.displayName) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.enterDisplayName;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			if (!this.ageCheck) {
				this.status.ok = false;
				this.status.message = `${this.languageErrorStrings.ageCheckFalse}`;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			// There's probably a better way to pass data to the parent component
			// Two separate events were just the quickest way since we release in two days 07/19/24
			this.newAccountProperty.propertyName = "displayName";
			this.newAccountProperty.propertyValue = this.displayName;
			this.eventBus.emit("addNewAccountProperty", this.newAccountProperty);

			this.newAccountProperty.propertyName = "ageCheck";
			this.newAccountProperty.propertyValue = this.ageCheck;
			this.eventBus.emit("addNewAccountProperty", this.newAccountProperty);

			this.eventBus.emit("advanceComponent");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-container {
	display: flex;
	flex-direction: column;
	margin: 15px auto;
	padding: 15px;
	max-width: 30em;
}

input {
	font-size: 1.5rem;
	height: 3.5vh;
	color: #6305c9;
}

.center-items {
	display: flex;
	align-items: center;
}

.encore-style-button-container {
	margin-top: 2vh;
}
</style>
